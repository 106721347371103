.container-auth {
  background: #2b3041;
  display: flex;
  /* padding: 50px;
    margin: 100px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 103vh;
  margin-top: -3vh;
}

.container-auth form {
  background: white;
  margin: 100px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.container-auth input {
  height: 40px;
  width: 250px;
  text-align: center;
}

.container-auth p {
  margin-top: 15px;
}

.auth-btn {
  font-size: 1.5vw;
  color: #2b3041;
  background-color: white;
  border-radius: 5px;
  width: 20vw;
  height: 3vw;
  margin-top: 3%;
  border: 2px solid #2b3041;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
}
.auth-btn:hover {
  background-color: rgb(236, 231, 231);
}
.btn-registrazione {
  color: #2b3041;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-bottom: -5px;
}
.link-btn-reg {
  cursor: pointer;
}
.auth-btn:active {
  width: 19.9vw;
  height: 2.9vw;
  background-color: lightcoral;
}
.auth-error {
  /* padding-top: 4%; */
  color: red;
}

.pass-dimenticata {
  color: #2b3041;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-top: 6px;
}
