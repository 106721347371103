@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;

 
}




.navbar {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--background-color);
  /* margin-top: -1.2vh; */
}

.menu-bars {
  height: 50px;
  margin-left: 2rem;
  font-size: 150%;
  background: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-decoration: none;
  background-color: #2b3041;
  margin-right: 0;
  border-bottom-left-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.menu-bars p {
  margin-right: 0.5vh;
  font-size: 80%;
  color: white;
  /* font-weight: bold; */
  margin-left: 1.5vw;
}
.menu-bars span {
  color: #2b3041;
  margin-right: 1.5vw;
}

.nav-menu {
  background-color: #2b3041;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  /* left: -100%; */
  left: -210px;
  transition: 850ms;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-text-mini {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  margin-right: -7.5px;
}

.nav-text-mini a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-text-mini span {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.nav-text-mini a:hover {
  background-color: #1a83ff;
}
.nav-text-mini span:hover {
  background-color: #1a83ff;
}
.nav-text-mini.active {
  justify-content: flex-start;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #2b3041;
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.home {
  width: 100%;
  display: flex;

  background-color: #fff;
  height: 100%;
}
.home-container {
  width: 100%;
  height: 100%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3%;
}
.home-container img {
  margin-bottom: 3%;
}
.form-container-ts {
  display: flex;
  flex-direction: column;
  width: 120%;
  padding-top: 3%;
  padding-bottom: 5%;
  align-items: center;
  justify-content: center;
  background-color: #2b3041;
}
.form-group {
  width: 40%;
}
.form-control {
  text-align: center;
}
.input-group {
  width: 40%;
}
.regolamento {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1%;
}
.p-chiede {
  margin-top: 3%;
}
.ts-text {
  font-size: 60%;
  color: gray;
}
.p-text {
  font-size: 90%;
}
.head-bar-ts {
  display: flex;
  justify-content: flex-end;
  margin-right: 2%;
  align-items: center;
}
.img-ts {
  height: 40vh !important;
}
@-webkit-keyframes new {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  50% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes new {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  50% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

/* mobile view */
@media (max-width: 767px) {
  .form-group {
    width: 75%;
  }
  .form-container-ts {
    width: 110%;
  }
}

.admin-ts {
  margin: 3%;
}
.option-ts {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-left: 3%;
  margin-top: 1%;
}

.data-pikers {
  display: flex;
  justify-content: flex-end;
  font-size: 10vh;
  align-items: flex-end;
  margin-left: 76vw;
  margin-right: 3vw;
  margin-top: -1%;
  position: absolute !important;
}
.data-calendar {
  position: absolute;
}

.icon-calendar {
  font-size: 2.5vw;
  margin-left: 3vw;

  color: white;
}

.data-pikers-date {
  height: 8vh;
  width: 20vw;
  font-size: 4.5vh;
  cursor: pointer;
  position: absolute !important;
}

.btn-date {
  color: white;
  height: 4.5vw;
  width: 20vw;
  margin-top: 2.7vw;
  background-color: #2b3041;
  border-color: #2b3041;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #2b3041;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 2vw;
}
.btn-date:hover {
  background-color: #4c79d8;
  border: 3px solid #4c79d8;
}

.input-e-data-pikers {
  display: flex;
  justify-content: space-between;
  margin-right: -2vw;
  align-items: center;
}
.input-e-data-pikers form input {
  height: 4vw;
  width: 100%;
  font-size: 120%;
  text-align: center;
  border: 3px solid;
  border-radius: 4px;
  border-color: #fd8c22;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #2b3041;
}
.input-e-data-pikers form input:active {
  border-color: #2b3041;
}
.input-e-data-pikers form input:focus {
  border-color: #2b3041;
  outline: none;
  border: 3px solid #2b3041;
}

.container-auth {
  background: #2b3041;
  display: flex;
  /* padding: 50px;
    margin: 100px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 103vh;
  margin-top: -3vh;
}

.container-auth form {
  background: white;
  margin: 100px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.container-auth input {
  height: 40px;
  width: 250px;
  text-align: center;
}

.container-auth p {
  margin-top: 15px;
}

.auth-btn {
  font-size: 1.5vw;
  color: #2b3041;
  background-color: white;
  border-radius: 5px;
  width: 20vw;
  height: 3vw;
  margin-top: 3%;
  border: 2px solid #2b3041;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
}
.auth-btn:hover {
  background-color: rgb(236, 231, 231);
}
.btn-registrazione {
  color: #2b3041;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-bottom: -5px;
}
.link-btn-reg {
  cursor: pointer;
}
.auth-btn:active {
  width: 19.9vw;
  height: 2.9vw;
  background-color: lightcoral;
}
.auth-error {
  /* padding-top: 4%; */
  color: red;
}

.pass-dimenticata {
  color: #2b3041;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-top: 6px;
}

.auth-btn-reg {
  font-size: 2vh;
  color: #2b3041;
  background-color: white;
  border-radius: 5px;
  width: 25vh;
  height: 4vh;
  margin-top: 10%;
  border: 2px solid #2b3041;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
}
.auth-btn-reg:hover {
  background-color: rgb(236, 231, 231);
}

