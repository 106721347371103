.home {
  width: 100%;
  display: flex;

  background-color: #fff;
  height: 100%;
}
.home-container {
  width: 100%;
  height: 100%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3%;
}
.home-container img {
  margin-bottom: 3%;
}
.form-container-ts {
  display: flex;
  flex-direction: column;
  width: 120%;
  padding-top: 3%;
  padding-bottom: 5%;
  align-items: center;
  justify-content: center;
  background-color: #2b3041;
}
.form-group {
  width: 40%;
}
.form-control {
  text-align: center;
}
.input-group {
  width: 40%;
}
.regolamento {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1%;
}
.p-chiede {
  margin-top: 3%;
}
.ts-text {
  font-size: 60%;
  color: gray;
}
.p-text {
  font-size: 90%;
}
.head-bar-ts {
  display: flex;
  justify-content: flex-end;
  margin-right: 2%;
  align-items: center;
}
.img-ts {
  height: 40vh !important;
}
@keyframes new {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.95);
  }
  100% {
    transform: scaleX(1);
  }
}

/* mobile view */
@media (max-width: 767px) {
  .form-group {
    width: 75%;
  }
  .form-container-ts {
    width: 110%;
  }
}
