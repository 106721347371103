.data-pikers {
  display: flex;
  justify-content: flex-end;
  font-size: 10vh;
  align-items: flex-end;
  margin-left: 76vw;
  margin-right: 3vw;
  margin-top: -1%;
  position: absolute !important;
}
.data-calendar {
  position: absolute;
}

.icon-calendar {
  font-size: 2.5vw;
  margin-left: 3vw;

  color: white;
}

.data-pikers-date {
  height: 8vh;
  width: 20vw;
  font-size: 4.5vh;
  cursor: pointer;
  position: absolute !important;
}

.btn-date {
  color: white;
  height: 4.5vw;
  width: 20vw;
  margin-top: 2.7vw;
  background-color: #2b3041;
  border-color: #2b3041;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #2b3041;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 2vw;
}
.btn-date:hover {
  background-color: #4c79d8;
  border: 3px solid #4c79d8;
}

.input-e-data-pikers {
  display: flex;
  justify-content: space-between;
  margin-right: -2vw;
  align-items: center;
}
.input-e-data-pikers form input {
  height: 4vw;
  width: 100%;
  font-size: 120%;
  text-align: center;
  border: 3px solid;
  border-radius: 4px;
  border-color: #fd8c22;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #2b3041;
}
.input-e-data-pikers form input:active {
  border-color: #2b3041;
}
.input-e-data-pikers form input:focus {
  border-color: #2b3041;
  outline: none;
  border: 3px solid #2b3041;
}
