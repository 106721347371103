.admin-ts {
  margin: 3%;
}
.option-ts {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-left: 3%;
  margin-top: 1%;
}
